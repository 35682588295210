$(function(){
	$('.m-nav span').addClass('toggled');

	var margin = $('header nav').height();
	margin = margin * -1;

	//$('header nav').css('marginTop', margin);

	$('.m-nav span').on('click', function(){
		if( $(this).hasClass('toggled') ) {
			$('header nav').slideToggle();
			$(this).removeClass('toggled');
		} else {
			$('header nav').slideToggle();
			$(this).addClass('toggled');
		}
	});

	$('.faq-question').first().next('.faq-answer').slideToggle(250).end().first().find('i').toggleClass('open');
	$('.faq-question').on('click', function(){
		$(this).next('.faq-answer').slideToggle(250);

		$(this).find('i').toggleClass('open');
	});

    $('.help-text-trigger').on('click', function () {
        var helpText = $(this).attr('data-help-text');
        $('body').append('<div id="help-text-container"><div id="help-text-inner"><span id="help-text-inner-exit">+</span><span id="help-text-inner-content">' + helpText + '</span></div></div>');
    });

    $('body').on('click', '#help-text-container', function (e) {
        var clickedId = $(e.target).attr('id');
        if (clickedId !== 'help-text-inner' && clickedId !== 'help-text-inner-content') {
            $('#help-text-container').remove();
        }
    })
});

