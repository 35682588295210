function addQueryString() {
   var mfType = $('.options.selected').attr('data-mf');
   $('.swiper-container .button').attr('href', '/order-service/mail-forwarding/?mf=' + mfType);
}

function offset() {
   var offset = $('.swiper-slide-active').attr('data-slide') - 1;
   if(offset > 0) {
      $('.options').first().css({
         marginLeft : '-' + (offset * 60 - 20 ) + '%'
      });
   } else {
      $('.options').first().css({
         marginLeft : '0%'
      });
   }
}

$(function(){
   var swiper = new Swiper('.swiper-container', {
      autoHeight: true,
		loop: false,
		pagination: '.swiper-pagination',
		paginationClickable: true,
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		spaceBetween: 30,
      onSlideChangeEnd: function () {
         slideIndex = $('.swiper-slide-active').attr('data-slide') - 1;
         $('.options.selected').removeClass('selected');
         $('.options').eq(slideIndex).addClass('selected');

         packageName = $('.selected .option-heading span').text();
         $('.swiper-container .button span').text(packageName);
         addQueryString();
         offset();
      }
	});    

  	var packageName = $('.selected .option-heading span').text();
   	$('.swiper-container .button span').text(packageName);

   addQueryString();

   var slideIndex;
   $('.options').on('click', function(){
   	$(this).addClass('temp-selected');
   		var x = 0;
   		$('.option-table .options').each(function(){
   			if($(this).hasClass('temp-selected')){
   				slideIndex = x;
   				$(this).removeClass('temp-selected');


   			}
   			x++;
   		});
   		
   		swiper.slideTo(slideIndex);
   		$('.options.selected').removeClass('selected');
   		$(this).addClass('selected');

   		packageName = $('.selected .option-heading span').text();
   		$('.swiper-container .button span').text(packageName);
         addQueryString();
         offset();
   });
});